body {
  padding: 0;
  margin: 0;
}

h1 {
  margin: 0;
  color: #fffAF0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: clamp(80px, 8vw, 200px);
}

h2 {
  margin: 0;
  color: #fffAF0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: clamp(40px, 2vw, 50px);
}

h3 {
  margin: 0;
  color: #fffAF0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 50px;
}

h4 {
  margin: 0px;
  color: #fffAF0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 35px;
}

p {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: clamp(20px, 2vw, 30px);
  color: #fffAF0;
}

label {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

a:hover {
  color: #5b92e5;
  text-decoration: none;
  cursor: pointer;
}

.bodyDiv {
  /* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,0,139,1) 100%); */
  /* background: linear-gradient(90deg,#1b2b44,#365789); */
  background: #1b2b44;
  height: 100%;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 25px;
  padding-top: 25px;
}

.aboutBodyDiv {
  background: #dee9f9;
  height: 100%;
  padding-left: 10%;
  padding-right: 10%;
  margin: 0px;
  padding-bottom: 25px;
  padding-top: 25px;
}

.h2-blue-headers {
  color: #5b92e5;
}

.h2-blue-headers-small {
  font-size: 60px;
  color: #5b92e5;
}

.h3-blue-headers {
  color: #5b92e5;
}

.h4-blue-headers {
  color: #5b92e5;
}

.p-focus {
  font-size: 23px;
  color: #fffAF0;
  margin-left: 10%;
  margin-right: 10%;
}

.p-focus small {
  font-size: clamp(15px, 2vw, 20px);
  text-align: justify;
}

.p-body {
  color: #365789;
}

.p-body-medium {
  color: #365789;
  font-size: 21px;
}

.p-body-small {
  color: #365789;
  font-size: 19px;
}

.p-body-xsmall {
  color: #365789;
  font-size: 15px;
}

.list-medium {
  color: #365789;
  font-size: 21px;
}

.list-small {
  color: #365789;
  font-size: 17px;
}

.glow {
  font-size: 30px;
  color: #fff;
  text-align: center;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.row {
  padding-left: 0;
  padding-right: 0;
  margin-right: 0;
  margin-left: 0;
}

.high-level-objectives {
  color: #365789;
  font-size: 20px;
}

.nested-ul {
  font-size: 16px;
  color: #365789;
}

@-webkit-keyframes glow {
  from {
    text-shadow: 0 0 5px #5b92e5, 0 0 10px #5b92e5, 0 0 15px #5b92e5, 0 0 20px #5b92e5, 0 0 25px #5b92e5, 0 0 30px #5b92e5, 0 0 35px #5b92e5;
  }

  to {
    text-shadow: 0 0 10px #5b92e5, 0 0 15px #5b92e5, 0 0 20px #5b92e5, 0 0 25px #5b92e5, 0 0 30px #5b92e5, 0 0 35px #5b92e5, 0 0 40px #5b92e5;
  }
}

#answer-choice {
  background-color: #dee9f9;
  transition: 0.3s;
  margin-right: 60%;
}

#answer-choice:hover {
  opacity: 0.4;
}

.anchor {
  color: #5b92e5;
}

.anchor:hover {
  text-decoration: underline;
}

.button {
  display: inline-block;
  background-color: #5b92e5;
  color: white;
  padding: 8px 15px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  margin: 5px 0;
  border: 0;
  text-decoration: none;
  font-size: 16px;
}

button:focus {
  outline: 2px solid #fff;
} 

.outline-button {
  display: inline-block;
  background-color: transparent;
  color: #5b92e5;
  padding: 8px 15px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  margin: 5px 0;
  border: 1px solid #5b92e5;
  text-decoration: none;
  font-size: 16px;
}

.outline-button:hover {
  color: white;
  background-color: #5b92e5;
}

.login-button {
  background-color: #00b256;
  border: 1px solid #00b256;
}

.login-button:hover {
  background-color: #008540;
  border: 1px solid #008540;
}

.logout {
  background-color: #fd6e6e;
  border: 1px solid #fd6e6e;
}

.logout:hover {
  background-color: #ff5151;
  border: 1px solid #ff5151;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
}

.react-player { 
  position: absolute;
  top: 0;
  left: 0;
}

.video-container {
  width: 100%;
  max-width: 500px;
  min-width: 250px;
  height: auto;
}

.image-text-container {
  display: flex;
  gap: 20px;
  padding: 15px 0;
}

@media screen and (max-width: 1000px) {
  .image-text-container {
    flex-direction: column;
  }
}

.correct {
 color: #009924;
}

.incorrect {
  color: #cb0000;
}

code {
  padding: 2px 5px!important;
  white-space : pre-wrap !important;
  vertical-align: middle;
}

pre code {
  white-space: pre!important;
}

/* Fractions */

.frac {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  letter-spacing: 0.001em;
  text-align: center;
}

.frac>span {
  display: block;
  padding: 0.1em;
}

.frac span.bottom {
  border-top: thin solid black;
}

.frac span.symbol {
  display: none;
}