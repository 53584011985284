.auth {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10vh;
    background-color: #1b2b44;
    font-family: Arial, sans-serif;
}

.auth-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    background-color: #dee9f9;
    padding: 50px;
    border-radius: 5px;
}

.auth-textbox {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: none;
}

/* .auth-google {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: #fff;
    color: #5b92e5;
    margin-bottom: 30px;
}

.google-img {
    height: 20px;
    width: 20px;
} */

.title-span {
    color:#5b92e5
}

.auth-title {
    margin-top: 10vh;
    font-size: clamp(10px, 60px, 100px);
    text-align: center;
}

.notification {
    background-color: #5b92e5;
    width: 50%;
    position: fixed;
    top: -900px;
    left: 0;
    right: 0;
    padding: 10px;
    text-align: center;
    text-overflow: ellipsis;
    border-radius: 5px;
    color: white;
    margin: auto;
}

.animated {
    animation: slide-in 1s forwards;
}

@keyframes slide-in {
    from {
      transform: translateY(-200%);
    }
    to {
      transform: translateY(910px);
    }
  }

