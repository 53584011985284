.Collapsible {
    background-color: #1B2B44;
    border-radius: 5px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.Collapsible__trigger {
    font-size: 20px;
    color: #fff;
    margin: 10px;
    line-height: 40px;
}

.Collapsible__contentInner {
    padding: 10px;
    background-color: #a5c8df;
    border-radius: 0 0 5px 5px;
}

.aboutBodyDiv {
    padding-bottom: 300px;
}

/* Slide animation for components. */
.slide-enter {
    transform: translateY(-100%);
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
  }
  
  .slide-enter-active {
    transform: translateY(0%);
    opacity: 1;
  }
  
  .slide-exit {
    transform: translateY(0%);
    opacity: 1;
  }
  
  .slide-exit-active {
    transform: translateY(100%);
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
  }
  