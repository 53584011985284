.contact-section {
  max-width: 900px;
  margin: 50px auto;
  display: flex;
  background-color: #1B2B44;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
}

.contact-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
}

.contact-logo {
  width: 75%;
  height: auto;
}

.form-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #a5c8df;
  height: 100%;
  width: 60%;
  border-radius: 5px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0;
  gap: 10px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  color: #1B2B44;
}

.form-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #fff;
  border-radius: 3px;
  background-color: #fff;
}

.form-item {
  flex: 1;
}

.email-button {
  background-color: #1B2B44;
}

.more-info {
  display: inline;
  position: relative;
}

.more-info:hover::after {
  content: 'Two payments: half at start date and half at end date';
  color: #fff;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  background-color: #1B2B44;
  display: inline-block;
  width: 150px;
  height: max-content;
  margin: auto;
  border-radius: 5px;
  z-index: 1;
}

@media screen and (max-width: 800px) {
  .form-row {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: stretch;
  }

  .form-item {
    flex: 100%;
    margin-right: 0;
  }

  .form-container {
    width: 100%;
    border-radius: 5px;
  }

  .contact-logo-container {
    display: none;
  }
}