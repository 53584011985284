.lesson-navigation {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: white;
}

.lesson-navigation a {
    color: #fff;
    text-decoration: none;
    font-size: clamp(.5rem, 3vw, 1rem);
}

.lesson-navigation span {
    color: white;
    padding: 0 10px;
    border-radius: 5px;
    font-size: clamp(.5rem, 3vw, 1rem);
}

.dropbtn {
    position: relative;
    color: white;
    background-color: transparent;
    border-radius: 5px;
    font-size: clamp(.5rem, 3vw, 1rem);
    border: none;
    white-space: nowrap;
}

.dropdown-content {
    position: absolute;
    left: 0;
    top: 27px;
    background-color: #1b2b44;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    text-align: left;
    padding: 10px;
    gap: 2px;
    border: 2px solid white;
    box-shadow: 1px 8px 20px 0px black;
}

.dropdown-content a {
    font-size: clamp(.8rem, 3vw, 1rem);
}

.dropdown-content a:hover {
    color: #53a1ff;
}

.fa-angle-down {
    margin: 0 0 0 10px;
}

.lessons-container {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    background-color: #a5c8df;
}

.lesson-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 5px;
    background-color: #fff;
    transition: background-color 0.3s ease;
}

.lesson-item:last-of-type {
    margin-bottom: 0;
}

.lesson-link {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: clamp(14px, 3vw, 21px);
}

.lesson-status {
    display: flex;
    align-items: center;
}

.lesson-checkbox {
    height: 25px;
    width: 25px;
    cursor: pointer;
    margin-left: 10px;
}

.comments-section {
        margin-top: 50px;       
}

.comment {
        border: 1px solid #ddd;
        padding: 15px;
        margin-bottom: 15px;
        border-radius: 5px;
        background-color: #f9f9f9;
}

.comment p {
margin: 0 0 10px;
}

.comment small {
        display: block;
        color: #666;
}
      