.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1b2b44;
    padding: 10px;
  }
  
  .navbar-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-right: 30px;
  }
  
  .navbar-menu-icon {
    display: none;
    flex-direction: column;
    cursor: pointer;
    z-index: 99;
    padding-right: 10px;
  }

  .menu-line {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 3px;
  }

  @media screen and (max-width: 768px) {
    .navbar-buttons {
      display: none;
    }
  
    .navbar-buttons .outline-button, .button {
      width: 100%;
    }
  
    .navbar-menu-icon {
      display: flex;
    }
  
    .navbar-buttons.open {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      right: 0;
      background-color: #1b2b44;
      padding: 150px 50px;
      gap: 50px;
    }
  
    .header-buttons {
      flex-direction: column;
      padding-bottom: 90px;
    }
  }

.Logo__button {
        border: 0 !important;
        border-radius: 0 !important;
        background-color: #5b92e5;
        color: white;
        transition: background-color 0.3s;
}

.Logo__button:hover {
        background-color: #365789;
}

.Logo__login-button {
        border: 0 !important;
        border-radius: 0 !important;
        background-color: green !important;
        color: white !important;
}

.Logo__logout-button {
        border: 0 !important;
        border-radius: 0 !important;
        background-color: #fd6e6e !important;
        color: black !important;
}